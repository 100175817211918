import React from 'react'
import moment from 'moment';
const TimeAgo = ({dat}) => {
  
    // الحصول على المنطقة الزمنية من المتصفح
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
console.log(userTimezone)
  // تحويل التاريخ إلى توقيت محلي للعميل
  const localTime = moment.utc(dat).tz(userTimezone);
  console.log(localTime)
  return (
    <>
    {localTime.fromNow()}
  </>
  )
}

export default TimeAgo