import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Constants/enviroment";

const get = (endPoint, setLoading, setData) => {
  axios
    .get(`${config.baseUrl}/${endPoint}`)
    .then((res) => {
      setLoading(false);
      console.log(res);
      setData(res);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};

export default get;
