import React from "react";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"

const TrackableLink = ({ href, children }) => {
  const appInsights = useAppInsightsContext();
  const trackExternalLinkClick = useTrackEvent(
    appInsights,
    "ExternalLinkClick"
  );

  const handleClick = (event) => {
    var url = event.currentTarget.href;
    trackExternalLinkClick({ url });
  };

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  );
};

export default TrackableLink;
