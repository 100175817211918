import React from 'react'
import logo1 from "../../Assets/Imeges/sot-icon.png"
import "./Header.css"
import TrackableLink from "../../Components/TrackableLink/TrackableLink"
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"

const Header = () => {
  return (
    
    <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
          <img src={logo1} />
          <h3>Street Things Deployer</h3>
          </a>
         
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav  ms-auto">
              <li className="nav-item">
                <TrackableLink className="nav-link " aria-current="page" href="https://abdulrhman.dev">Contact us</TrackableLink>
              </li>
              <li className="nav-item">
                <TrackableLink className="nav-link" href="https://deploy.streetThings.abdulrhman.dev/project">Source code</TrackableLink>
              </li>
              <li className="nav-item">
                <TrackableLink className="nav-link" href="https://dev.streetThings.abdulrhman.dev">Dev environment</TrackableLink>
              </li>
              <li className="nav-item">
                <TrackableLink className="nav-link" href="https://test.streetThings.abdulrhman.dev">Test environment</TrackableLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
   
  
  )
}

export default Header