import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../Constants/enviroment";
import { useNavigate } from "react-router-dom";

const usePost = (endpoint) => {
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();

  const sendPostRequest = (body) => {
    setIsLoading("loading");

    return axios
      .post(`${config.baseUrl}/${endpoint}`, body)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        return res;
      })
      .catch((err) => {
        setIsLoading(true);
        throw err;
      });
  };

  return [isLoading, sendPostRequest];
};

export default usePost;
