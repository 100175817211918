import React from "react";
import "./Footer.css";
import TrackableLink from "../../Components/TrackableLink/TrackableLink";
const Footer = () => {
  return (
    <>
      <div className="container-fluid footer">
        <div className="textfooter">
          <ul className="list-footer">
            <p>Source Code</p>
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/Infrastructure">Infrastructure (Terraform) </TrackableLink>
            </li>
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/Authorization">Authorization Service (Asp.net)</TrackableLink>
            </li>
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/Catalog">Catalog Service (Asp.net)</TrackableLink>
            </li>
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/AdminGateway">Admin Gateway (Asp.net w/ Ocelot)</TrackableLink>
            </li>
            <li>
              <TrackableLink>
                {" "}
                Projects and shared libraries.
              </TrackableLink>
            </li>
          </ul>
        </div>
        <div className="textfooterr">
          <ul className="list-footerr">
            <p>Credits</p>
            <li> <TrackableLink href="https://abdulrhman.dev"> Abd Alrifai (Back-end developer). </TrackableLink></li>
            <li> <TrackableLink>Ghazal Zarour (Front-end developer).</TrackableLink></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
