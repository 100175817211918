import React, {useState } from 'react'
import "./MyBox.css"
import TimeAgo from '../TimeAgo/TimeAgo';
import usePost from '../../CustomHooks/usePost';
import config from '../../Constants/enviroment';
import { NavLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"
import { useNavigate } from "react-router-dom";


const MyBox = ({ overallStatus, isRunning ,datt, selectedEnvironment }) => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, sendPostRequest] = usePost(config.reCaptcha);
  const navigate = useNavigate();

  const handleCaptchaVerify = (value) => {
    sendPostRequest(
      {
        "environmentName": selectedEnvironment , "recaptcha": value
      }
    ).then((res) =>{
      setMessage("A build has been triggered. Navigating to the logs page...");
      setTimeout(() => {
        navigate(`/logs?env=${selectedEnvironment}`);
      }, 5000);
    }).catch((err) =>{
      if(err.message.contains("already running")){
        setMessage("A build is already running. Navigating to the logs page...");
        setTimeout(() => {
          navigate(`/logs?env=${selectedEnvironment}`);
        }, 5000);
      }
      else{
        setMessage("An error has been occured");
      }
    });
  };

  const appInsights = useAppInsightsContext();
  const trackExtraLinkClick = useTrackEvent(appInsights, "Exstra Link Click")
  const handleExternalLinkClick = (event) => {
    const clickLink = event.currentTarget;
    const url = clickLink.href;
    trackExtraLinkClick({ url });
  };

  const trackStartVerification = useTrackEvent(appInsights, 'Star Verification');
  const handleClickk = () => {
    setShowCaptcha(true);
    trackStartVerification()
  }
 
  return (
    <div> {overallStatus === 'Completed' && isRunning ?
      (<div className="my-element">
        <p>StreetThings was deployed <TimeAgo dat={datt} /> by another user and is currently running.
          You can try it out using the following <a href='https://api.deploy.streetthings.abdulrhman.dev' onClick={handleExternalLinkClick}> link.</a>
          Additionally,
          you can view the latest<NavLink to={`/logs?env=${selectedEnvironment}`} > deployment logs </NavLink>  for the application's infrastructure and services

        </p>

      </div>) : (
        <div>

          {overallStatus === 'InProgress' ?

            (<div className='my-elementt'>

              <p>StreetThings is currently being deployed.The deployment was triggered by another user  <TimeAgo dat={datt} />.
                You can check the current deployment status
                <NavLink to={`/logs?env=${selectedEnvironment}`} > here. </NavLink>


              </p>
            </div>) :

            (<div className='my-elementt'>

              <p>StreetThings is currently decommissioned. Proceeding will provision the infrastructure,
                create a Kubernetes cluster, and deploy the application services</p>
              <div>
                <button onClick={handleClickk}>  Deploy StreetThings</button>

                {showCaptcha && (
                  <ReCAPTCHA
                    sitekey={`${config.sitekey}`}
                    onChange={handleCaptchaVerify}
                  />

                )}



              </div>
              {message && <div className='err-message'>{message}</div>}


              <p className='p-text'>StreetThings will be up for one Hour, afterwards the services and infrastructure will be back to the decommisioned state.
                You can check the last build logs infrastructure and services deployment
                <NavLink to={`/logs?env=${selectedEnvironment}`} > here.</NavLink></p>
            </div>
            )}
        </div>
      )}
    </div>
  );
};

export default MyBox;
